import React, { forwardRef } from "react";
import { LIST_BULLET_TYPE } from "./constants";
import { ListWrapper, ListItemWrapper, ListItemIconWrapper } from "./styles";
import {
	Icon,
	Text,
	Separator,
	CloudinaryImage,
} from "@website-builder/ui/shared/elements";
import { isBusinessVerticalB2U } from "@website-builder/utilities/utils/helpers.js";
const isB2U = isBusinessVerticalB2U();
const VerticalList = forwardRef(
	(
		{
			listItems = [],
			iconConfig = {},
			gap,
			separator,
			seperatorColor,
			textVariant = "paragraph_M",
			darkBg,
			listBulletType,
			customBulletList = null,
			...restProps
		},
		ref,
	) => {
		const generateIndexNumber = (index) =>
			index < 9 ? `0${index + 1}` : `${index + 1}`;
		const getIconStroke = (iconName, strokeColor, darkBg) => {
			if (isB2U && strokeColor) {
				return strokeColor;
			}
			if (iconName === "checkCircle") {
				return darkBg
					? `var(--theme-secondary-light, var(--primary-green-400))`
					: "var(--theme-primary, var(--primary-forest-400))";
			}
			return strokeColor;
		};
		const generateBullets = (listBulletType, config, index) => {
			if (listBulletType === LIST_BULLET_TYPE.Checkmarks) {
				const { iconName, size, stroke } = config;
				return (
					<Icon
						className="list-item-icon"
						iconName={iconName}
						stroke={getIconStroke(iconName, stroke, darkBg)}
						size={size || "1.125rem"}
					/>
				);
			} else if (listBulletType === LIST_BULLET_TYPE.Numbers) {
				return (
					<Text
						tag="h4"
						variant="headline_1"
						color={{ base: "secondary", color: "blue", shade: "300" }}
					>
						{generateIndexNumber(index)}
					</Text>
				);
			} else if (listBulletType === LIST_BULLET_TYPE.Custom) {
				const customBulletType =
					Array.isArray(customBulletList) && customBulletList?.[index]?.type;
				if (customBulletType === LIST_BULLET_TYPE.Icon) {
					const iconConfig = customBulletList?.[index] || {};
					const { iconName, size, stroke, fill } = iconConfig;
					return (
						<Icon
							className="list-item-icon"
							iconName={iconName}
							stroke={stroke}
							fill={fill}
							size={size || "1.125rem"}
						/>
					);
				}
				if (customBulletType === LIST_BULLET_TYPE.Image) {
					const imageConfig = customBulletList?.[index] || {};
					return (
						<CloudinaryImage
							url={imageConfig?.image}
							className="bullet-image"
							quality="auto"
							lazyload
							fetchFormat="auto"
						/>
					);
				}
				return null;
			} else {
				const { iconName = "", size, stroke } = iconConfig;
				return (
					<>
						{iconName ? (
							<Icon
								className="list-item-icon"
								iconName={iconName}
								stroke={getIconStroke(iconName, stroke, darkBg)}
								size={size || "1.125rem"}
							/>
						) : null}
					</>
				);
			}
			return null;
		};

		return (
			<ListWrapper gap={gap} separator={separator} ref={ref} {...restProps}>
				{listItems.map((item, index) => {
					return (
						<React.Fragment key={index}>
							<ListItemWrapper key={index} className="list-item">
								{iconConfig && iconConfig.iconName ? (
									<ListItemIconWrapper aria-hidden="true">
										{generateBullets(listBulletType, iconConfig, index)}
									</ListItemIconWrapper>
								) : null}
								{React.isValidElement(item) ? (
									item
								) : (
									<Text variant={textVariant}>{item}</Text>
								)}
							</ListItemWrapper>
							{separator && index !== listItems.length - 1 && (
								<Separator darkBg={darkBg} color={seperatorColor} />
							)}
						</React.Fragment>
					);
				})}
			</ListWrapper>
		);
	},
);

export default VerticalList;
