import styled from "styled-components";

export const ListWrapper = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;
	.bullet-image {
		width: 5rem;
		height: 6rem;
		border-radius: 8px;
	}
	& > li:not(:last-child) {
		margin-bottom: ${(props) => (props.gap ? props.gap : "1rem")};
	}
	& > li:not(:first-child) {
		margin-top: ${(props) =>
			props.separator ? (props.gap ? props.gap : "1rem") : "0"};
	}
`;

export const ListItemWrapper = styled.li`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
`;

export const ListItemIconWrapper = styled.figure`
	margin: 0 1.5rem 0 0;
	display: inline-block;
	line-height: 0;
`;
